<template>
  <div>
    <img src="./../../assets/images/enterSchoolCode/top_image.png" class="topImage" alt="">

    <van-form class="form" @submit="onSubmit">
      <van-field
          v-model="schoolCode"
          input-align="center"
          placeholder="请输入学校编码"
      />
      <div class="submitBtn">
        <van-button block type="primary" native-type="submit">提交</van-button>
      </div>
    </van-form>


    <div class="tips">
      <div class="title">温馨提示：</div>
      <div class="content">
        <p>1、第一次学生注册前须绑定学校编码；</p>
        <p>2、校服订购告知函、通知书中均注明了学校编码，请您留意查看；</p>
        <p>3、如忘记学校编码，可咨询学校、班主任或其他家长。</p>
      </div>
    </div>

    <xc-privacy-popup ref="privacyComponent" position="center" @allowPrivacy="allowPrivacy"/>
  </div>
</template>

<script>
import Vue from "vue";
import XcPrivacyPopup from "../../components/xc-privacyPopup/xc-privacyPopup.vue";

export default {
  name: "EnterSchoolCode",
  components: {XcPrivacyPopup},
  data() {
    return {
      schoolCode: '',
      schoolObj:{},
    }
  },
  mounted() {
    Object.assign(this.$data, this.$options.data())
    this.schoolCode = '';
    this.schoolObj = {};
  },
  activated(){
    Object.assign(this.$data, this.$options.data())
    this.schoolCode = '';
    this.schoolObj = {};
  },
  methods: {
    onSubmit() {
      if (!this.schoolCode) {
        this.$toast({message: '请输入学校编码'})
        return;
      }

      let codeRule = /^[0-9]{4}$/;

      if (!codeRule.test(this.schoolCode)) {
        this.$toast({message: '学校编码错误'})
        return;
      }

      return new Promise(() => {
        this.$http.get(this.baseMallUrl + `/h5_mall/querySchoolCode/${this.schoolCode}/${Vue.prototype.deptId}`).then(res => {
          this.$refs.privacyComponent.closePrivacy('',res.data.id);
          this.schoolObj = res.data.school;

        })
      })
      // TODO  Send Server

    },
    // 同意隐私协议
    allowPrivacy() {
      this.$router.push({path:'/student/add',query:{schoolObj:this.schoolObj}})
      //判断学校下有没有学生数据
      // this.$http.get(`/mall/h5_mall/queryStudentBySchoolId`, {
      //   params: {
      //     schoolId: this.schoolObj.id,
      //     userId: Vue.prototype.userId
      //   }
      // }, {
      //   emulateJSON: true
      // }).then(res => {
      //   if (res.data.length == 0) {
      //   }else if(res.data.length == 1){
      //     //添加默认学生
      //     this.$http.post(this.baseMallUrl + `/h5_mall/switchDefaultStudents/${res.data[0].id}`, {
      //     }, {emulateJSON: true}).then(() => {
      //       //操作成功 获取学校信息
      //       this.$router.push({path:'/',query:{}})
      //     })
      //   }else if  (res.data.length > 1){
      //     this.$router.push({path:'/student/newList',query:{schoolId:this.schoolObj.id}})
      //   }
      // })
    },
  },
}

</script>
<style lang="less" scoped>
.topImage {
  width: 100vw;
  margin-bottom: 60px;
}

.submitBtn {
  margin: 50px 18px 0;
}

::v-deep .van-cell {
  padding: 14px 18px;
}

::v-deep .van-field__control {
  font-size: 18px;
  letter-spacing: 20px;
}


.tips {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;

  line-height: 20px;
  margin: 67px 18px 0;

  .title {
    color: #FF0000;
    font-weight: 500;
  }

  p {
    color: #999999;
  }
}
</style>
